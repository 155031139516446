import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '99%',
        paddingTop: 3,
    },

}, {
    name: 'chat-ai',
});

export default useStyles;
