import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
    },
    searchContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '150px',
        backgroundColor: "#F4F3F3",
    },
    newsCardListContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 20,
    },
}, {
    name: 'home',
});

export default useStyles;
