import React, {useEffect, useRef, useState} from "react";
import ContnetLayout from "../../../components/layout/contentLayout/ContnetLayout";
import useStyles from "./styles";
import SearchBar from "../../../components/searchBar/SearchBar";
import CardGroup from "../../../components/cardGroup/CardGroup";
import {ContextTypeEnum, ICard, IContext} from "../../../globalTypes/globalTypes";
import {getCardBy} from "../../../Services/HttpRequest";
import {useLocation, useNavigate} from "react-router-dom";
import {cards} from "../../mainPages/home/Home";
import {Button, Typography} from "@mui/material";
import IconBack from "../../../assets/icons/IconBack";
import IconForward from "../../../assets/icons/IconForward";
import IconCollapse from "../../../assets/icons/IconCollapse";
import IconExpand from "../../../assets/icons/IconExpand";
import {useHistory} from "../../../providers/HistoryProvider";
import Showdown from 'react-showdown';

const Views = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const styles = useStyles()
    const navigate = useNavigate();

    const markdownRef = useRef<HTMLDivElement | null>(null);
    const [lawCard, setLawCard] = useState<ICard | null>(null);
    const [referedLawCard, setReferedLawCard] = useState<ICard | null>(null);
    const [inputValue, setInputValue] = useState<string>("")
    const [isFocusInContent, setIsFocusInContent] = useState<boolean>(false);
    const { addHistory, goBack, goForward, canGoBack, canGoForward } = useHistory();
    const [referId, setReferId] = useState<string>("");

    const getCard = async (id: string) => {
        try {
            const fetchedCard = await getCardBy(id);
            if ((fetchedCard as ICard).id) {
                return fetchedCard as ICard;
            } else {
                return null;
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (id !== null) {
            getCard(id).then(card => {
                setLawCard(card ?? cards[0]);
            })
        }
    }, [id]);

    useEffect(() => {
        if (referId !== null) {
            getCard(referId).then(card => {
                card && setReferedLawCard(card);
            });
        }
    }, [referId]);

    useEffect(() => {
        if (lawCard?.content === undefined) { return };
            const detectLinks = () => {
            const links = markdownRef.current?.querySelectorAll('a');
            links?.forEach(link => {
                link.addEventListener('click', (event) => {
                    event.preventDefault(); // Prevent default navigation behavior
                    const id: string = (event.target as HTMLAnchorElement).getAttribute('href') ?? "";
                    // setReferId(id);
                    navigate(`/views?id=${id}`);
                });
            });
        };

        detectLinks()

        return () => {
            const links = markdownRef.current?.querySelectorAll('a');
            links?.forEach(link => {
                link.removeEventListener('click', () => {}); // Remove event listeners
            });
        };
    }, [lawCard]);

    function getAllTextNodes(node: Node): Text[] {
        let textNodes: Text[] = [];
        if (node.nodeType === Node.TEXT_NODE) {
            textNodes.push(node as Text);
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            node.childNodes.forEach(child => {
                textNodes = textNodes.concat(getAllTextNodes(child)); // Recursively collect text nodes
            });
        }
        return textNodes;
    }
    const isValidViewPage = (pathname: string, search: string) => {
        return pathname === '/views' && search.length > 1;
    };

    useEffect(() => {
        if (isValidViewPage(location.pathname, location.search)) {
            const currentPath = location.pathname + location.search;
            addHistory(currentPath);
        }
    }, [location, addHistory]);

    const handleBack = () => {
        const previousPath = goBack();
        if (previousPath) {
            navigate(previousPath);
        }
    };

    const handleForward = () => {
        const nextPath = goForward();
        if (nextPath) {
            navigate(nextPath);
        }
    };

    const searchAndSelect = (text: string) => {
        const searchText = text.toLowerCase(); // The text you're searching for
        const element = document.querySelector('#main-content') as HTMLElement; // Adjust this selector

        if (element) {
            const textContent = element.innerText.toLowerCase(); // Get all text content
            const index = textContent.indexOf(searchText); // Find the index of the search text

            if (index !== -1) {
                const range = document.createRange();
                const selection = window.getSelection();

                // Collect all text nodes within the main content
                const allTextNodes = getAllTextNodes(element);
                console.log("All Text Nodes:", allTextNodes); // Debugging log

                let currentIndex = 0; // Reset cumulative index

                for (const textNode of allTextNodes) {
                    const nodeText = textNode.textContent || '';
                    const nodeLength = nodeText.length;

                    if (currentIndex + nodeLength >= index) {
                        const startOffset = index - currentIndex; // Calculate start offset
                        const endOffset = startOffset + searchText.length; // Calculate end offset

                        range.setStart(textNode, startOffset); // Set start of the range
                        range.setEnd(textNode, endOffset); // Set end of the range

                        selection?.removeAllRanges(); // Clear any existing selections
                        selection?.addRange(range); // Select the range
                        break; // Exit the loop after selection
                    }

                    currentIndex += nodeLength; // Update the cumulative index
                }
            } else {
                console.log("Text not found.");
            }
        } else {
            console.log("Element not found.");
        }
    };

    return (
        <div className={styles.root} >
            <div className={styles.searchContainer}>
                <SearchBar
                    title={"Որոնել"}
                    placeholder={"Որոնվող տեքստ"}
                    onSerach={text => searchAndSelect(text)}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            </div>
            <ContnetLayout
                left={<div />}
                center={
                <div className={styles.controlContainer}>
                    <Button variant={"text"}
                            disabled={!canGoBack()}
                            onClick={handleBack}>
                        <IconBack className={canGoBack() ? styles.backIcon : styles.inactiveBackIcon}/>
                    </Button>
                    <Button variant={"text"}
                            onClick={() => setIsFocusInContent(!isFocusInContent)}>
                        {isFocusInContent ? <IconCollapse className={styles.targetIcon} /> : <IconExpand className={styles.targetIcon} />}
                    </Button>
                    <Button variant={"text"}
                            onClick={handleForward}>
                        <IconForward className={canGoForward() ? styles.backIcon : styles.inactiveBackIcon}/>
                    </Button>
                </div>
            }
                right={<div />}
            />
            {!isFocusInContent ? (
                <ContnetLayout
                    isFocusInContent={isFocusInContent}
                    left={
                        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                            {lawCard?.incoming?.map((context: IContext) => (
                                <CardGroup cardID={lawCard?.id} context={context}
                                           contextType={ContextTypeEnum.inGoing}/>))}
                        </div>
                    }
                    center={
                        <div className={styles.mainMarkDownContainer}>
                            <div className={styles.markDownContainer} id={"main-content"} ref={markdownRef}>
                                <div className={styles.markDown}>
                                    <Typography variant={"h6"} textAlign={"center"}>{lawCard?.title}</Typography>
                                    {lawCard && <Showdown markdown={lawCard.content}/>}
                                </div>
                            </div>
                                {referedLawCard && (
                                    <div className={styles.markDownContainer} id={"main-content"} >
                                        <div className={styles.markDown}>
                                            <Button onClick={() => {
                                                setReferedLawCard(null);
                                                setReferId("");
                                            }}>
                                                Close
                                            </Button>
                                            <Typography variant={"h6"}
                                                        textAlign={"center"}>{referedLawCard?.title}</Typography>
                                            <Showdown markdown={referedLawCard.content}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                    }
                    right={
                        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                            {lawCard?.outgoing?.map((context: IContext) => (
                                <CardGroup cardID={lawCard?.id} context={context}
                                           contextType={ContextTypeEnum.outGoing}/>))}
                        </div>
                    }
                />
            ) : (
                <ContnetLayout
                    isFocusInContent={isFocusInContent}
                    center={
                        <div className={styles.mainMarkDownContainer}>
                            <div className={styles.markDownContainer} id={"main-content"} ref={markdownRef}>
                                <div className={styles.markDown}>
                                    <Typography variant={"h6"} textAlign={"center"}>{lawCard?.title}</Typography>
                                    {lawCard && <Showdown markdown={lawCard.content}/>}
                                </div>
                            </div>
                            {referedLawCard && (
                                <div className={styles.markDownContainer} id={"main-content"} >
                                    <div className={styles.markDown}>
                                        <Button onClick={() => {
                                            setReferedLawCard(null);
                                            setReferId("");
                                        }}>
                                            Close
                                        </Button>
                                        <Typography variant={"h6"}
                                                    textAlign={"center"}>{referedLawCard?.title}</Typography>
                                        <Showdown markdown={referedLawCard.content}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                />
            )}
        </div>
    )
}

export default Views;
